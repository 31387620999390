import { CommentOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, FileAddOutlined } from "@ant-design/icons"
import { Button, Card, Empty, Flex, Input, List, Modal, Space, Upload } from "antd"
import { useState } from "react"
import { usePipeline } from "../../../../hooks/usePipeline"
import { colors, spaces } from "../../../../utils/styles"
import { Loader } from "../../../atoms/animations/Loader/Loader"
import { AssetClassSelect } from "../../../atoms/select/AssetClassSelect/AssetClassSelect"
import { PipelineAssetStatusSelect } from "../../../atoms/select/PipelineAssetStatusSelect/PipelineAssetStatusSelect"
import { Comment } from "../../inputs/Comment/Comment"
import "./DetailPipelineAssetModal.css"

export const DetailPipelineAssetModal = ({ onClose }) => {
  const {
    selectedAsset: asset,
    isManagementTeam,
    isLoadingAttachments,
    updateStatus,
    updateClass,
    updateDescription,
    updateName,
    getAuthorName,
    addComment,
    deleteComment,
    editComment,
    addAttachment,
    downloadAttachment,
    deleteAttachment,
  } = usePipeline()

  const [isAddingComment, setIsAddingComment] = useState(false)
  const [isEditingDescription, setIsEditingDescription] = useState(false)
  const [isEditingName, setIsEditingName] = useState(false)

  const FOOTER = (
    <Space>
      <Upload
        beforeUpload={(file) => {
          addAttachment(file)
          return false
        }}
        showUploadList={false}
      >
        <Button key="1" type="primary" style={{ backgroundColor: "black" }} icon={<FileAddOutlined />} disabled={!isManagementTeam}>
          Novo Anexo
        </Button>
      </Upload>
      <Button
        key="2"
        type="primary"
        style={{ backgroundColor: colors.green }}
        onClick={() => setIsAddingComment(true)}
        icon={<CommentOutlined />}
        disabled={!isManagementTeam}
      >
        Novo comentário
      </Button>
      <Button key="3" type="primary" onClick={() => setIsEditingDescription(true)} icon={<EditOutlined />} disabled={!isManagementTeam}>
        Editar descrição
      </Button>
      <Button key="4" type="primary" onClick={() => setIsEditingName(true)} icon={<EditOutlined />} disabled={!isManagementTeam}>
        Editar nome
      </Button>
    </Space>
  )

  const iconStyle = { fontSize: "18px", cursor: "pointer" }

  const isAttachmentEmpty = !asset || !asset.attachments || !asset.attachments.length
  const isCommentEmpty = !asset || !asset.comments || !asset.comments.length

  const secureDelete = (attachment) => {
    Modal.confirm({
      title: "Você tem certeza que deseja deletar esse anexo?",
      content: "Esta ação não pode ser desfeita.",
      okText: "Excluir",
      okButtonProps: { style: { backgroundColor: colors.primary } },
      cancelText: "Cancelar",
      onOk: () => deleteAttachment(attachment),
    })
  }

  const Attachments = () => {
    if (isLoadingAttachments) return <Loader />
    if (isAttachmentEmpty)
      return (
        <Card>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Nenhum anexo registrado" style={{ height: 50, margin: 0 }} />
        </Card>
      )
    return (
      <List
        bordered
        style={{ overflowY: "auto", maxHeight: "30vh" }}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description="Nenhum anexo registrado!" />,
        }}
      >
        {asset?.attachments?.map((attachment, index) => (
          <List.Item style={{ backgroundColor: index % 2 === 1 ? "#ececec" : "#fff" }} key={attachment.id}>
            <Flex style={{ width: "100%", gap: spaces.space1 }}>
              {attachment.filename}
              <DownloadOutlined style={iconStyle} onClick={() => downloadAttachment(attachment.filename)} />
              {isManagementTeam && <DeleteOutlined style={iconStyle} onClick={() => secureDelete(attachment)} />}
            </Flex>
          </List.Item>
        ))}
      </List>
    )
  }

  return (
    <Modal
      open
      title={
        isEditingName ? (
          <Input
            autoFocus
            style={{ width: "70vw" }}
            defaultValue={asset?.name}
            onPressEnter={(e) => updateName(e.target.value).then(() => setIsEditingName(false))}
          />
        ) : (
          <h3 style={{ margin: 0 }}>{asset?.name}</h3>
        )
      }
      onCancel={onClose}
      footer={FOOTER}
      style={{ top: spaces.space4, bottom: spaces.space4 }}
      width={"80vw"}
      height={"10vh"}
    >
      <Flex vertical style={{ marginTop: spaces.space2, marginBottom: spaces.space2, gap: spaces.space2 }}>
        {isEditingDescription ? (
          <Input.TextArea
            autoFocus
            style={{ width: "70vw" }}
            defaultValue={asset?.description}
            onPressEnter={(e) => !e.shiftKey && updateDescription(e.target.value).then(() => setIsEditingDescription(false))}
          />
        ) : (
          <Space>{asset?.description}</Space>
        )}
        <Flex gap={spaces.space2}>
          <PipelineAssetStatusSelect status={asset?.status} setStatus={updateStatus} width={200} disabled={!isManagementTeam} />
          <AssetClassSelect setAssetClass={updateClass} value={asset?.class} disabled={!isManagementTeam} />
        </Flex>
        <h3 style={{ margin: 0 }}>Anexos</h3>
        {Attachments()}
        <h3 style={{ margin: 0 }}>Comentários</h3>
        {isCommentEmpty && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Nenhum comentário registrado" style={{ height: 100, margin: 0 }} />
        )}
        {!isCommentEmpty &&
          asset?.comments?.map((comment) => (
            <Comment
              author={comment.author}
              date={comment.created_at}
              content={comment.content}
              onSubmit={(content, date) => editComment(comment.id, date, content)}
              showEditControl={isManagementTeam}
              onDelete={() => deleteComment(comment.id)}
            />
          ))}
        {isAddingComment && (
          <Comment
            isNewComment
            onSubmit={(content, date) => {
              addComment(content, date), setIsAddingComment(false)
            }}
            onCancel={() => setIsAddingComment(false)}
            author={getAuthorName()}
          />
        )}
      </Flex>
    </Modal>
  )
}
