import { Button, Col, Modal, Radio, Row, Select } from "antd"
import { useContext, useEffect, useState } from "react"
import { useAssetClass } from "../../../../hooks/useAssetClass"
import { AssetsContext } from "../../../../context/AssetsContext"
import { ASSET_LOCALES } from "../../../../utils/enums"
import { GraphViewer } from "./GraphViewer"
import { findPaths, replaceSpacesByUnderlines } from "../../../../utils/utils"
import { GlobalContext } from "../../../../context/GlobalContext"
import { spaces } from "../../../../utils/styles"

export const AssetInfoModal = ({ asset, onClose }) => {
  const [assetClassesOptions, setAssetClassesOptions] = useState([])
  const [assetClass, setAssetClass] = useState(null)
  const [locale, setLocale] = useState(asset.locale)
  const [hasPermissionToEdit, setHasPermissionToEdit] = useState(false)
  const [graphData, setGraphData] = useState([])
  const { updateAssetClass, getAssetClass } = useAssetClass()
  const { setForceReload, assetGraph, customers, customerSelectedId } = useContext(AssetsContext)

  const getCustomerOrFundSelected = () => customers.length && customers.find((customer) => customer.id === customerSelectedId)?.name

  const { supabase } = useContext(GlobalContext)

  useEffect(() => {
    if (!supabase) return
    supabase.auth.getSession().then(({ data }) => {
      const email = data?.session?.user?.email
      const allowedEmails = [
        "felipe.nunes@teracapital.com.br",
        "rafael.rocha@teracapital.com.br",
        "adri.costa@teracapital.com.br",
        "italo.nunes@teracapital.com.br",
        "joao.vaz@teracapital.com.br",
        "victor.koyama@teracapital.com.br",
        "mario.quaresma@teracapital.com.br",
        "pedro.monteiro@teracapital.com.br",
      ]
      setHasPermissionToEdit(allowedEmails.includes(email))
    })
  }, [supabase])

  useEffect(() => {
    const start = getCustomerOrFundSelected()
    const end = asset.key.toUpperCase()

    if (!start) {
      return setGraphData({
        nodes: [{ data: { id: 1, label: "Com a visão explodida marcada, escolha um cliente/fundo para visualizar o grafo" } }],
        edges: [],
        start: 1,
        end: 1,
      })
    }

    const edges = []
    const nodes = []
    const originAlreadyInserted = []

    findPaths(assetGraph, start, end).forEach(({ origin, target, value_brl, value_usd }) => {
      const originId = replaceSpacesByUnderlines(origin)
      const targetId = replaceSpacesByUnderlines(target)

      const insertIfNotExist = (value, id) => {
        if (!originAlreadyInserted.includes(value)) {
          originAlreadyInserted.push(value)
          nodes.push({ data: { id, label: value, bold: [start, end].includes(value) } })
        }
      }

      insertIfNotExist(origin, originId)
      insertIfNotExist(target, targetId)

      edges.push({
        data: {
          source: originId,
          target: targetId,
          value_brl: new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(value_brl),
          value_usd: new Intl.NumberFormat("pt-BR", { style: "currency", currency: "USD" }).format(value_usd),
        },
      })
    })

    setGraphData({ nodes, edges, start: replaceSpacesByUnderlines(start), end: replaceSpacesByUnderlines(end) })
  }, [assetGraph, asset, customerSelectedId, customers])

  useEffect(() => {
    getAssetClass({ locale })
      .then((assetClasses) => {
        setAssetClassesOptions(assetClasses.map((item) => ({ value: item.id, label: item.name })))
      })
      .catch((error) => console.error(error))
  }, [locale])

  useEffect(() => {
    if (assetClassesOptions.length > 0 && asset.idClass && asset.idClass != 74) {
      setAssetClass((assetClassesOptions.find((item) => item.value === asset.idClass) || {}).value)
    }
  }, [assetClassesOptions])

  const handleClose = () => {
    setAssetClass(null)
    setLocale(null)
    onClose()
  }

  const handleOk = () => {
    if (!assetClass || !asset.idAsset) return handleClose()
    updateAssetClass(asset.idAsset, assetClass).then(() => {
      setForceReload(true)
      handleClose()
    })
  }

  const LABEL_SPAN = 8

  return (
    <>
      <Modal
        title={asset?.key}
        open={asset != null}
        onCancel={handleClose}
        maskClosable={true}
        footer={
          hasPermissionToEdit && [
            <Button key="ok" type="primary" onClick={handleOk}>
              Salvar
            </Button>,
          ]
        }
      >
        <div style={{ padding: spaces.space0 }}>
          <Row style={{ marginTop: spaces.space2 }}>
            <Col span={LABEL_SPAN}>
              <b>Localidade:</b>
            </Col>
            <Col>
              <Radio.Group onChange={(e) => setLocale(e.target.value)} defaultValue={locale} disabled={!hasPermissionToEdit}>
                <Radio value={ASSET_LOCALES.onshore.value}>Onshore</Radio>
                <Radio value={ASSET_LOCALES.offshore.value}>Offshore</Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row style={{ marginTop: spaces.space2 }}>
            <Col span={LABEL_SPAN}>
              <b>Classe do Ativo:</b>
            </Col>
            <Col>
              <Select
                options={assetClassesOptions}
                style={{ width: 250 }}
                value={assetClass}
                onChange={setAssetClass}
                disabled={!locale || !hasPermissionToEdit}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: spaces.space2 }}>
            <GraphViewer elements={graphData} />
          </Row>
        </div>
      </Modal>
    </>
  )
}
