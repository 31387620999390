import { Flex } from "antd"
import { spaces } from "../../../../utils/styles"

export const ShowDate = ({ date, title }) =>
  date && (
    <Flex gap={spaces.space1} style={{ alignItems: "center" }}>
      <b>{title}: </b>
      <p>{date}</p>
    </Flex>
  )
