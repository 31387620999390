import { message } from "antd"
import { useContext, useEffect, useState } from "react"
import { PipelineContext } from "../context/PipelineContext"
import { PipelineService } from "../service/PipelineService"
import { useStorage } from "./useStorage"
import { useSession, useSupabase } from "./useSupabase"

export const usePipeline = () => {
  const supabase = useSupabase()
  const { createFile, downloadFile, deleteFile } = useStorage({ bucket: "data-lake" })
  const session = useSession()
  const context = useContext(PipelineContext)
  const service = PipelineService(supabase)
  const [assets, setAssets] = useState([])
  const [isLoadingAttachments, setIsLoadingAttachments] = useState(false)
  const { classFilter, nameFilter, forceReload } = context
  const pipelineId = context.selectedAsset?.id
  const pipelineFolder = `pipeline/${pipelineId}`

  const names = {
    "felipe.nunes@teracapital.com.br": "Felipe Nunes",
    "rafael.rocha@teracapital.com.br": "Rafael Rocha",
    "adri.costa@teracapital.com.br": "Adri Costa",
    "italo.nunes@teracapital.com.br": "Italo Nunes",
    "joao.vaz@teracapital.com.br": "João Vaz",
    "victor.koyama@teracapital.com.br": "Victor Koyama",
    "mario.quaresma@teracapital.com.br": "Mario Quaresma",
    "pedro.monteiro@teracapital.com.br": "Pedro Monteiro",
  }

  const isManagementTeam = names[session.user.email] !== undefined
  const getName = (email) => names[email]

  const refreshAssets = () => service.getPipelineAssets({ classFilter, nameFilter }).then(setAssets)

  useEffect(() => {
    if (assets.length > 0 && context.selectedAsset) {
      context.setSelectedAsset(assets.find((asset) => asset.id === pipelineId))
    }
  }, [assets])

  const createPipeline = async (pipeline) => {
    await service.createAsset(pipeline)
    forceReload()
  }

  const updateStatus = async (status) => {
    await service.updateStatus(status, pipelineId)
    forceReload()
  }

  const updateClass = async (classId) => {
    await service.updateClass(classId, pipelineId)
    forceReload()
  }

  const updateName = async (name) => {
    await service.updateName(name, pipelineId)
    forceReload()
  }

  const updateDescription = async (description) => {
    await service.updateDescription(description, pipelineId)
    forceReload()
  }

  const addComment = async (comment, date) => {
    await service.addComment(comment, date, getName(session.user.email), pipelineId)
    forceReload()
  }

  const deleteComment = async (commentId) => {
    await service.deleteComment(commentId)
    forceReload()
  }

  const addAttachment = async (attachment) => {
    setIsLoadingAttachments(true)

    let file

    try {
      file = await createFile(pipelineFolder, attachment)
    } catch (error) {
      setIsLoadingAttachments(false)
      if (error.error === "Duplicate") return message.error("Já existe um arquivo com esse nome nesse pipeline")
    }

    await service.addAttachment(file.path.split("/").at(-1), getName(session.user.email), pipelineId)

    forceReload()

    setIsLoadingAttachments(false)
  }

  const downloadAttachment = async (filename) => downloadFile(pipelineFolder, filename)

  const deleteAttachment = async (attachment) => {
    setIsLoadingAttachments(true)

    try {
      await Promise.all([service.deleteAttachment(attachment.id), deleteFile(pipelineFolder, attachment.filename)])
      forceReload()
    } finally {
      setIsLoadingAttachments(false)
    }
  }

  const editComment = async (commentId, date, comment) => {
    await service.editComment(commentId, date, comment)
    forceReload()
  }

  const getAuthorName = () => names[session.user.email]

  return {
    assets,
    isLoadingAttachments,
    deleteComment,
    addComment,
    createPipeline,
    updateStatus,
    updateName,
    updateClass,
    updateDescription,
    addAttachment,
    downloadAttachment,
    deleteAttachment,
    refreshAssets,
    editComment,
    getAuthorName,
    isManagementTeam,
    ...context,
  }
}
