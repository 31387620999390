import { ASSET_LOCALES, ASSET_VISUALIZATION_TYPE } from "../utils/enums"
import { fetchAllData, formatDate, getLastDateAvailable, setNestedKey, tables } from "../utils/utils"
import { ASSET_POSTIONS_COLUMNS } from "../utils/reportsConfig"
import { useContext } from "react"
import { GlobalContext } from "../context/GlobalContext"

const EMPTY_CLASS = "Não classificado"
const TERA_RV = 14

export const useAssets = ({
  customerSelectedId,
  visualizationType,
  locale,
  hideEmptyClasses,
  dimBase = tables.DIM_CUSTOMER,
  isToViewExploded = false,
}) => {
  const fact_table = isToViewExploded ? "fact_asset_allocation_exploded" : "fact_asset_allocation"

  const { supabase } = useContext(GlobalContext)

  const getSupabaseData = async ({ customerSelectedId, locale }) => {
    const dimAssetQuery = supabase.from("dim_asset").select("id, name, dim_asset_class_id")
    const dimAssetClassQuery = supabase.from("dim_asset_class").select("id, name, id_parent")
    const customersQuery = supabase.from(dimBase).select("id, name").order("name")

    const lastDateAvailable = await getLastDateAvailable(supabase, fact_table)

    const positionsQuery = supabase
      .from(isToViewExploded ? "fact_asset_allocation_exploded" : "fact_asset_allocation")
      .select(
        `id,
       quantity,
       value_brl,
       value_usd,
       date,
       locale,
       ${dimBase} ( name ),
       dim_asset ( name, is_illiquid ),
       dim_asset_class ( id, name, id_parent )`,
      )
      .filter("date", "eq", lastDateAvailable)

    if (dimBase === tables.DIM_CUSTOMER) positionsQuery.filter("dim_tera_fund_id", "is", null).not("dim_customer_id", "is", null)
    else {
      positionsQuery.filter("dim_customer_id", "is", null).not("dim_tera_fund_id", "is", null).not("dim_tera_fund_id", "eq", TERA_RV)
      customersQuery.not("id", "eq", TERA_RV)
    }

    if (customerSelectedId) positionsQuery.filter(`${dimBase}_id`, "eq", customerSelectedId)

    if (locale && locale != ASSET_LOCALES.all.value) {
      positionsQuery.filter("locale", "eq", locale)
      dimAssetQuery.filter("locale", "eq", locale)
      dimAssetClassQuery.filter("locale", "eq", locale)
    }

    positionsQuery.order("dim_asset ( name )")
    dimAssetClassQuery.order("id")

    const assetGraphQuery = supabase
      .from("fact_asset_allocation")
      .select("dim_asset ( name ), dim_tera_fund ( name ), dim_customer ( name ), value_brl, value_usd")
      .filter("date", "eq", lastDateAvailable)
      .order("dim_asset ( name )")

    const dimAsset = await fetchAllData(dimAssetQuery)

    return {
      customers: await fetchAllData(customersQuery),
      positions: await fetchAllData(positionsQuery),
      classes: await fetchAllData(dimAssetClassQuery),
      mapAssetClasses: dimAsset.reduce((acc, { name, dim_asset_class_id }) => ({ ...acc, [name]: dim_asset_class_id }), {}),
      mapAssetId: dimAsset.reduce((acc, { name, id }) => ({ ...acc, [name]: id }), {}),
      assetGraph: (await fetchAllData(assetGraphQuery)).map(({ dim_asset, dim_tera_fund, dim_customer, value_brl, value_usd }) => ({
        origin: dim_customer?.name || dim_tera_fund?.name,
        target: dim_asset.name,
        value_brl,
        value_usd,
      })),
    }
  }

  const transformMapToAntdChildrenFormat = (map, classes, customers, totals, mapAssetClasses, mapAssetId) => {
    const sort = (a, b) => {
      if (a.isParent && !b.isParent) return -1
      if (!a.isParent && b.isParent) return 1
      if (a.key === EMPTY_CLASS) return -1
      if (b.key === EMPTY_CLASS) return 1
      return a.key.localeCompare(b.key)
    }

    return Object.entries(map)
      .reduce((acc, [key, value]) => {
        if (key === "isClass") return acc

        if (value.isClass) {
          const children = transformMapToAntdChildrenFormat(value, classes, customers, totals, mapAssetClasses, mapAssetId).sort(sort)

          const customersTotals = customers.reduce((acc, customer) => ({ ...acc, [customer]: 0 }), {})

          children.forEach((child) => {
            Object.entries(child).forEach(([key, value]) => {
              if (customers.includes(key)) customersTotals[key] += value
            })
          })

          return acc.push({ key, assetClass: key, isParent: true, children, ...customersTotals }), acc
        }

        const result = {
          key,
          assetClass: key,
          idClass: mapAssetClasses[key],
          idAsset: mapAssetId[key],
          locale: value.locale,
          isIlliquid: value.is_illiquid,
        }

        if (!totals) return acc.push({ ...result, ...value }), acc

        Object.entries(value).forEach(([customerName, customerValue]) => {
          if (!customers.includes(customerName)) return
          const total = totals[customerName]
          result[customerName] = customerValue / total
        })

        return acc.push(result), acc
      }, [])
      .sort(sort)
  }

  const getTotals = (positions, customers) => {
    return customers.reduce((acc, { name }) => {
      acc[name] = positions.reduce((total, { value_usd, [dimBase]: group }) => {
        if (group.name === name) total += value_usd
        return total
      }, 0)
      return acc
    }, {})
  }

  const findPathToRoot = (classes, className) => {
    const assetClass = classes.find(({ name }) => name === className)
    const path = [assetClass.name]

    let parent = classes.find(({ id }) => id === assetClass.id_parent)

    while (parent) {
      path.unshift(parent.name)
      parent = classes.find(({ id }) => id === parent.id_parent)
    }

    return path
  }

  const getAssetsClassPath = ({ classes }) => {
    return classes.reduce((acc, { name }) => {
      acc[name] = findPathToRoot(classes, name)
      return acc
    }, {})
  }

  const generateInitialTree = ({ assetClassPath }) => {
    return Object.values(assetClassPath).reduce((acc, path) => {
      let current = acc

      path.forEach((name) => {
        if (!current[name]) current[name] = { isClass: true }
        current = current[name]
      })

      return acc
    }, {})
  }

  const getAssetsTree = (positions, classes, visualizationType) => {
    const assetClassPath = getAssetsClassPath({ classes })
    const initialTree = generateInitialTree({ assetClassPath })
    return positions.reduce((acc, { dim_asset_class, dim_asset, [dimBase]: group, value_brl, value_usd, quantity, locale }) => {
      const assetName = dim_asset.name
      const path = assetClassPath[dim_asset_class?.name]

      let value

      if (visualizationType === ASSET_VISUALIZATION_TYPE.amount_usd.value) value = value_usd
      else if (visualizationType === ASSET_VISUALIZATION_TYPE.amount_brl.value) value = value_brl
      else if (visualizationType === ASSET_VISUALIZATION_TYPE.quantity.value) value = quantity
      else value = value_usd

      setNestedKey(acc, [...path, assetName, "locale"], locale)
      setNestedKey(acc, [...path, assetName, "is_illiquid"], dim_asset.is_illiquid)

      return setNestedKey(acc, [...path, assetName, group.name], value)
    }, initialTree)
  }

  const convertToConsolidatedView = (data) => {
    const parentsConsolidatedIndexes = {
      [EMPTY_CLASS]: 0,
      Cash: 1,
      "Cash BZ": 1,
      "Core Bonds": 2,
      "Core Bonds BZ": 2,
      "Credit Bonds": 3,
      "Credit Bonds BZ": 3,
      "Credit Bonds Infra BZ": 3,
      Commodities: 4,
      "Commodities BZ": 4,
      FX: 5,
      "FX BZ": 5,
      Gold: 6,
      "Gold BZ": 6,
      "Hedge Funds": 7,
      "Hedge Funds BZ": 7,
      "Private Equity": 8,
      "Private Equity BZ": 8,
      "Public Equity": 9,
      "Public Equity BZ": 9,
      "Real Estate and Infrastructure": 10,
      "Real Estate and Infrastructure BZ": 10,
      "Private Investments": 11,
      "Private Investments BZ": 11,
    }

    const CLASSES_WITHOUT_CHILDREN_CLASSES = [EMPTY_CLASS]

    const dataConsolidated = Object.entries(parentsConsolidatedIndexes).reduce((acc, [key, index]) => {
      if (!acc[index]) acc[index] = { key: `${key}_consolidated`, assetClass: key, isParent: true, children: [] }
      return acc
    }, [])

    data.forEach((parent) => {
      const index = parentsConsolidatedIndexes[parent.key]
      const newParent = dataConsolidated[index]

      if (!newParent) return

      if (CLASSES_WITHOUT_CHILDREN_CLASSES.includes(parent.key)) return (dataConsolidated[index] = parent)

      newParent.children.push(parent)

      Object.entries(parent).forEach(([key, value]) => {
        if (key === "key" || key === "assetClass" || key === "isParent" || key === "children") return
        if (!dataConsolidated[index][key]) dataConsolidated[index][key] = 0
        dataConsolidated[index][key] += value
      })
    })

    return hideEmptyClasses ? dataConsolidated.filter((parent) => parent.children.length) : dataConsolidated
  }

  return {
    getRecentPositions: async () => {
      let { positions, customers, classes, mapAssetClasses, mapAssetId, assetGraph } = await getSupabaseData({ customerSelectedId, locale })

      if (hideEmptyClasses) {
        const classesFound = positions.reduce((acc, { dim_asset_class, id }) => {
          if (dim_asset_class) findPathToRoot(classes, dim_asset_class.name).forEach((name) => acc.add(name))
          return acc
        }, new Set())

        classes = classes.filter(({ name }) => classesFound.has(name))
      }

      const data = transformMapToAntdChildrenFormat(
        getAssetsTree(positions, classes, visualizationType),
        classes,
        customers.map(({ name }) => name),
        visualizationType === ASSET_VISUALIZATION_TYPE.percent_on_total.value ? getTotals(positions, customers) : null,
        mapAssetClasses,
        mapAssetId,
      )

      return {
        customers,
        lastUpdate: formatDate(positions?.[0]?.date),
        data: locale === ASSET_LOCALES.all.value ? convertToConsolidatedView(data) : data,
        assetGraph,
      }
    },
    getRecentPositionsAsExcelFormat: async () => {
      /*
        TODO: cenários de teste
        - consolidado deve trazer tudo
        - offshore deve trazer offshore
        - onshore deve trazer onshore
        - cliente deve trazer cliente
        - fundo deve trazer fundo
      */
      const lastDateAvailable = await getLastDateAvailable(supabase, fact_table)

      const hasLocaleFiltered = locale !== ASSET_LOCALES.all.value

      const query = supabase
        .from(`${fact_table}_class_hierarchy`)
        .select("*")
        .filter("date", "eq", lastDateAvailable)
        .not(dimBase, "is", null)
        .order("dim_customer, dim_tera_fund, asset")

      if (customerSelectedId) query.filter(`${dimBase}_id`, "eq", customerSelectedId)
      if (hasLocaleFiltered) query.filter("locale", "eq", locale)

      const rows = await fetchAllData(query)

      return rows.map(({ dim_customer, dim_tera_fund, asset, date, value_usd, value_brl, locale, class: _class, is_illiquid }) => {
        const result = {}

        result[ASSET_POSTIONS_COLUMNS.date.title] = date
        result[ASSET_POSTIONS_COLUMNS.asset.title] = asset
        result[ASSET_POSTIONS_COLUMNS.value_usd.title] = value_usd
        result[ASSET_POSTIONS_COLUMNS.value_brl.title] = value_brl
        result[ASSET_POSTIONS_COLUMNS.is_illiquid.title] = is_illiquid

        if (dimBase === tables.DIM_CUSTOMER) result[ASSET_POSTIONS_COLUMNS.customer.title] = dim_customer
        else result[ASSET_POSTIONS_COLUMNS.fund.title] = dim_tera_fund

        if (!hasLocaleFiltered) result[ASSET_POSTIONS_COLUMNS.local.title] = locale

        _class
          .split(",")
          .reverse()
          .forEach((className, index) => {
            const key = index === 0 ? ASSET_POSTIONS_COLUMNS.class_parent.title : ASSET_POSTIONS_COLUMNS[`class_level_${index}`].title
            result[key] = className
          })

        return result
      })
    },
  }
}
