import { CheckOutlined } from "@ant-design/icons"
import { Button, Flex, Input, Modal } from "antd"
import { useState } from "react"
import { usePipeline } from "../../../../hooks/usePipeline"
import { spaces } from "../../../../utils/styles"
import { AssetClassSelect } from "../../../atoms/select/AssetClassSelect/AssetClassSelect"
import { PipelineAssetStatusSelect } from "../../../atoms/select/PipelineAssetStatusSelect/PipelineAssetStatusSelect"

export const NewPipelineAssetModal = ({ onClose }) => {
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [status, setStatus] = useState("")
  const [classId, setClassId] = useState(null)

  const { createPipeline } = usePipeline()

  const isValid = name && description && status && classId

  const FOOTER = (
    <Button
      type="primary"
      icon={<CheckOutlined />}
      disabled={!isValid}
      onClick={() => createPipeline({ name, description, status, classId }).then(onClose)}
    >
      Salvar
    </Button>
  )

  return (
    <Modal open title="Cadastrar novo ativo no pipeline" onCancel={onClose} width={700} footer={FOOTER}>
      <Flex vertical style={{ paddingTop: spaces.space1, paddingBottom: spaces.space1, gap: spaces.space2 }}>
        <Flex gap={spaces.space2}>
          <Flex style={{ alignItems: "center", gap: spaces.space1 }}>
            <b>Nome:</b>
            <Input style={{ width: 600 }} onChange={(e) => setName(e.target.value)} />
          </Flex>
        </Flex>
        <Flex gap={spaces.space2}>
          <PipelineAssetStatusSelect status={status} setStatus={setStatus} width={200} />
          <AssetClassSelect setAssetClass={setClassId} width={335} />
        </Flex>
        <Input.TextArea style={{ width: 700, height: 200 }} placeholder="Descrição sobre ativo" onChange={(e) => setDescription(e.target.value)} />
      </Flex>
    </Modal>
  )
}
