import { Flex, Select } from "antd"
import { useContext } from "react"
import { ASSET_VISUALIZATION_TYPE, DEFAULT_ASSET_VISUALIZATION_TYPE } from "../../../../utils/enums"
import { AssetsContext } from "../../../../context/AssetsContext"
import { spaces } from "../../../../utils/styles"

export const VisualizationTypeSelect = () => {
  const { visualizationType, setVisualizationType } = useContext(AssetsContext)

  return (
    <Flex gap={spaces.space1} style={{ alignItems: "center" }}>
      <b>Tipo de Visualização:</b>
      <Select
        style={{ width: 160 }}
        options={Object.values(ASSET_VISUALIZATION_TYPE)}
        value={visualizationType}
        defaultValue={DEFAULT_ASSET_VISUALIZATION_TYPE}
        onChange={setVisualizationType}
      />
    </Flex>
  )
}
