import { useContext } from "react"
import { classColors } from "../../../../utils/utils"
import { GlobalContext } from "../../../../context/GlobalContext"
import { Tag } from "antd"
import { spaces } from "../../../../utils/styles"

export const AssetClassTag = ({ id }) => {
  const { classes } = useContext(GlobalContext)
  const parents = classes.filter((assetClass) => assetClass.id_parent === null)
  const assetClass = parents.find((assetClass) => assetClass.id === id)
  return (
    <Tag color={classColors[assetClass?.name]} style={{ marginRight: 0, marginLeft: spaces.space1 }}>
      {assetClass?.name}
    </Tag>
  )
}
