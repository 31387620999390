import { useEffect, useState } from "react"
import { Flex, Select } from "antd"
import { spaces } from "../../../../utils/styles"
import { useSupabase } from "../../../../hooks/useSupabase"

export const MODES = {
  CUSTOMER: "customer",
  FUND: "fund",
}

export const CustomerOrFundSelect = ({ mode, value, setValue, isToUseNameAsValue = false }) => {
  const supabase = useSupabase()
  const [items, setItems] = useState([])

  useEffect(() => {
    if (!mode || !supabase) return () => {}

    supabase
      .from(mode === MODES.CUSTOMER ? "dim_customer" : "dim_tera_fund")
      .select("*")
      .order("name")
      .then(({ data }) => setItems(data))
  }, [mode])

  const defaultOption = { value: "all", label: "Todos" }
  const options = items.map((item) => ({
    value: isToUseNameAsValue ? item.name : item.id,
    label: item.name,
  }))
  options.unshift(defaultOption)

  const onChange = (value) => {
    if (value === defaultOption.value) return setValue(null)
    setValue(value)
  }

  return (
    <Flex gap={spaces.space1} style={{ alignItems: "center" }}>
      <b>{mode === MODES.CUSTOMER ? "Cliente: " : "Fundo: "}</b>
      <Select style={{ width: 240 }} options={options} value={value} onChange={onChange} defaultValue={defaultOption} />
    </Flex>
  )
}
