import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, DatePicker, Flex, Input, Modal } from "antd"
import { useState } from "react"
import { colors, spaces } from "../../../../utils/styles"
import { formatDate } from "../../../../utils/utils"
import dayjs from "dayjs"

export const Comment = ({ author, date, content, showEditControl = false, onDelete, onSubmit, onCancel = () => {}, isNewComment = false }) => {
  const [isEditting, setIsEditting] = useState(false)
  const [isCreating, setIsCreating] = useState(isNewComment)
  const [dateToEdit, setDateToEdit] = useState(date || dayjs().toISOString())
  const [contentToEdit, setContentToEdit] = useState(content)

  const iconStyle = { fontSize: "18px", cursor: "pointer" }

  const isReadOnly = !isCreating && !isEditting

  const handleOk = () => {
    onSubmit(contentToEdit, dateToEdit)
    setIsEditting(false)
    setIsCreating(false)
  }

  const secureDelete = () => {
    Modal.confirm({
      title: "Você tem certeza que deseja deletar esse comentário?",
      okText: "Excluir",
      okButtonProps: { style: { backgroundColor: colors.primary } },
      cancelText: "Cancelar",
      onOk: onDelete,
    })
  }

  const DATE_FORMAT = "YYYY-MM-DD"

  return (
    <Flex vertical style={{ gap: spaces.space1 }}>
      <Flex style={{ alignItems: "center", gap: spaces.space1 }}>
        <b>{author}</b>
        {isReadOnly && <b>{`(${formatDate(date)})`}</b>}
        {!isReadOnly && <DatePicker onChange={(e) => setDateToEdit(e.toISOString())} placeholder="Data" defaultValue={dayjs(date)} format={DATE_FORMAT} />}
        {showEditControl && (
          <>
            {!isEditting && <EditOutlined style={{ ...iconStyle }} onClick={() => setIsEditting(true)} />}
            <DeleteOutlined style={iconStyle} onClick={secureDelete} />
          </>
        )}
      </Flex>
      {isReadOnly && content?.split("\n").map((line) => <p style={{ margin: 0 }}>{line}</p>)}
      {!isReadOnly && (
        <>
          <Input.TextArea
            onPressEnter={(e) => !e.shiftKey && handleOk()}
            defaultValue={contentToEdit}
            onChange={(e) => setContentToEdit(e.target.value)}
            placeholder="Escreva um comentário..."
          />
          <Flex gap={spaces.space0}>
            <Button type="primary" style={{ width: 50 }} onClick={handleOk} disabled={!dateToEdit || !contentToEdit}>
              OK
            </Button>
            <Button
              onClick={() => {
                setIsEditting(false), setIsCreating(false), setDateToEdit(date), setContentToEdit(content), onCancel()
              }}
            >
              Cancelar
            </Button>
          </Flex>
        </>
      )}
    </Flex>
  )
}
