import { Button, Empty, Flex, Skeleton, Space, Table } from "antd"
import { useIPS } from "../../../hooks/useIPS"
import { Loader } from "../../atoms/animations/Loader/Loader"
import { PercentLabel } from "../../atoms/formatters/PercentLabel/PercentLabel"
import { useEffect, useState } from "react"
import { useStorage } from "../../../hooks/useStorage"
import { CheckOutlined, ArrowUpOutlined, ArrowDownOutlined, FileExcelFilled, PlusOutlined, MinusOutlined } from "@ant-design/icons"
import { downloadExcelFile, round } from "../../../utils/utils"
import { borders, colors, spaces } from "../../../utils/styles"
import { CustomerOrFundSelect } from "../../atoms/select/CustomerOrFundSelect/CustomerOrFundSelect"
import { MoneyLabel } from "../../atoms/formatters/MoneyLabel/MoneyLabel"

export const IPS = () => {
  const DEFAULT_CUSTOMER = "AMALFI"

  const { ips, dates, excelAllClients, totals } = useIPS()
  const { getImageURLWithToken } = useStorage({})
  const [selectedCustomer, setSelectedCustomer] = useState(DEFAULT_CUSTOMER)
  const [customerChartURL, setCustomerChartURL] = useState(null)
  const [isLoadingImage, setIsLoadingImage] = useState(false)
  const [hasIPS, setHasIPS] = useState(true)

  useEffect(() => {
    if (!dates) return () => {}
    if (!dates[selectedCustomer]) return setHasIPS(false)

    setHasIPS(true)
    setIsLoadingImage(true)

    getImageURLWithToken("asset-allocation/ips", `${selectedCustomer}-${dates[selectedCustomer]}.png`).then((url) => {
      setCustomerChartURL(url)
      setIsLoadingImage(false)
    })
  }, [selectedCustomer, dates])

  if (!ips || ips.length === 0) return <Loader />

  const getBalance = (row) => {
    const diff = round(row.current, 4) - round(row.target, 4)
    const margin = 0.01
    const amount = totals[selectedCustomer] * diff
    const balance = { diff, amount }

    if (Math.abs(diff) < margin) return { color: "green", icon: <CheckOutlined />, legend: "dentro do alvo", isTargetReached: true, ...balance }
    if (diff > 0) return { color: "red", icon: <ArrowDownOutlined />, legend: "para atingir o alvo", ...balance }
    return { color: "#0288D1", icon: <ArrowUpOutlined />, legend: "para atingir o alvo", ...balance }
  }

  let columns = [
    {
      title: "Estratégia",
      dataIndex: "strategy",
      key: "strategy",
    },
    {
      title: "Classe",
      dataIndex: "class_name",
      key: "class_name",
    },
    {
      title: "Posição atual",
      dataIndex: "current",
      key: "current",
      render: (value) => <PercentLabel value={value} decimalPlaces={2} />,
    },
    {
      title: "Alvo",
      dataIndex: "target",
      key: "target",
      render: (value) => <PercentLabel value={value} decimalPlaces={2} />,
    },
    {
      title: "Balanço (%)",
      dataIndex: "balance",
      key: "balance",
      render: (_, row) => {
        const { color, icon, legend, diff, isTargetReached } = getBalance(row)
        return (
          <Space style={{ color }}>
            {icon}
            {!isTargetReached && <PercentLabel value={Math.abs(diff)} decimalPlaces={2} />}
            {legend}
          </Space>
        )
      },
    },
    {
      title: "Balanço (US$)",
      dataIndex: "amount",
      key: "amount",
      render: (_, row) => {
        const { amount, color, isTargetReached } = getBalance(row)
        return <Space style={{ color }}>{isTargetReached ? "OK" : <MoneyLabel value={Math.abs(amount)} currency="USD" />}</Space>
      },
    },
  ]

  const chartSize = { width: 600, height: 406 }

  const handleExport = () => {
    if (!excelAllClients.length) return
    const columns = Object.keys(excelAllClients[0]).map((column) => ({ name: column, width: 20 }))
    downloadExcelFile(excelAllClients, "IPS", columns, "IPS.xlsx")
  }

  return (
    <Flex vertical>
      <Space style={{ borderBottom: borders.default, padding: spaces.space2, backgroundColor: colors.gray }}>
        <CustomerOrFundSelect mode="customer" value={selectedCustomer} setValue={setSelectedCustomer} isToUseNameAsValue={true} />
        <Button type="primary" style={{ backgroundColor: colors.green }} icon={<FileExcelFilled />} onClick={handleExport}>
          Exportar IPS de todos os clientes
        </Button>
      </Space>
      <Space style={{ justifyContent: "center", flexDirection: "column", gap: spaces.space1 }}>
        {!hasIPS ? (
          <Empty description="Cliente não possui IPS registrado" />
        ) : (
          <>
            <Space style={{ marginTop: spaces.space1 }}>
              {isLoadingImage ? <Skeleton style={chartSize} /> : <img src={customerChartURL} alt="Gráfico do cliente" style={chartSize} />}
            </Space>
            <Table
              dataSource={ips.filter((row) => row.customer === selectedCustomer)}
              columns={columns}
              pagination={false}
              size="small"
              rowClassName={(_, index) => (index % 2 === 0 ? "table-row-dark" : "")}
              style={{ border: borders.default, borderRadius: 6, width: 800 }}
            />
          </>
        )}
      </Space>
    </Flex>
  )
}
