export const getWidth = (columns, title) => Object.values(columns).find((column) => column.title === title).width

export const ASSET_POSTIONS_COLUMNS = {
  date: {
    title: "Data",
    width: 10,
  },
  asset: {
    title: "Ativo",
    width: 50,
  },
  value_brl: {
    title: "Valor (R$)",
    width: 20,
  },
  value_usd: {
    title: "Valor (US$)",
    width: 20,
  },
  customer: {
    title: "Cliente",
    width: 15,
  },
  fund: {
    title: "Fundo",
    width: 25,
  },
  local: {
    title: "Local",
    width: 10,
  },
  is_illiquid: {
    title: "Ilíquido",
    width: 10,
  },
  class_parent: {
    title: "Classe Pai",
    width: 35,
  },
  class_level_1: {
    title: "Classe Nível 1",
    width: 35,
  },
  class_level_2: {
    title: "Classe Nível 2",
    width: 35,
  },
  class_level_3: {
    title: "Classe Nível 3",
    width: 35,
  },
  class_level_4: {
    title: "Classe Nível 4",
    width: 35,
  },
  class_level_5: {
    title: "Classe Nível 5",
    width: 35,
  },
  class_level_6: {
    title: "Classe Nível 6",
    width: 35,
  },
  class_level_7: {
    title: "Classe Nível 7",
    width: 35,
  },
  class_level_8: {
    title: "Classe Nível 8",
    width: 35,
  },
  class_level_9: {
    title: "Classe Nível 9",
    width: 35,
  },
  class_level_10: {
    title: "Classe Nível 10",
    width: 35,
  },
}
