import { useContext } from "react"
import { getLastDateAvailable, tables } from "../utils/utils"
import { GlobalContext } from "../context/GlobalContext"

export const useCompliance = () => {
  const { supabase } = useContext(GlobalContext)

  const get = async (join) => {
    const lastUpdate = await getLastDateAvailable(supabase, tables.FACT_COMPLIANCE_MONITORING)
    const { data } = await supabase
      .from(tables.FACT_COMPLIANCE_MONITORING)
      .select(`${join} (name), rule, current, group, id`)
      .eq("date", lastUpdate)
      .not(`${join}_id`, "is", null)
      .order(`${join} (name), rule`)

    return data.reduce((acc, { rule, current, group, [join]: { name }, id }) => {
      if (!acc[name]) acc[name] = []
      acc[name].push({ rule, current, group, id })
      return acc
    }, {})
  }

  return {
    getTeraVehicle: async () => await get(tables.DIM_TERA_FUND),
    getClientFunds: async () => await get(tables.DIM_STRUCTURE),
  }
}
