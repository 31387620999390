import { Flex, Input } from "antd"
import { spaces } from "../../../../utils/styles"

export const AssetNameFilter = ({ setNameFilter, width = 300 }) => {
  return (
    <Flex style={{ gap: spaces.space2, alignItems: "center" }}>
      <b style={{ textWrap: "nowrap" }}>Nome do ativo:</b>
      <Input placeholder="Filtrar por nome" onChange={(e) => setNameFilter(e.target.value)} style={{ width }} />
    </Flex>
  )
}
