import { saveAs } from "../utils/utils"
import { useSupabase } from "./useSupabase"

export const useStorage = ({ bucket = "static" }) => {
  const supabase = useSupabase()

  const getLastestFileFrom = async (folder, isToUseRawBlob = false) => {
    const filesAvailable = (await supabase.storage.from(bucket).list(folder)).data
    const lastFile = filesAvailable.sort((a, b) => a.name - b.name).pop()
    const file = (await supabase.storage.from(bucket).download(`${folder}/${lastFile.name}`)).data
    return isToUseRawBlob ? file : await file.text()
  }

  const getImageURLWithToken = async (folder, fileName) => {
    const {
      data: { signedUrl },
    } = await supabase.storage.from(bucket).createSignedUrl(`${folder}/${fileName}`, 60)
    return signedUrl
  }

  const listFiles = async (folder) => {
    return (await supabase.storage.from(bucket).list(folder)).data
  }

  const createFile = async (folder, file, fileName = null) => {
    const { data, error } = await supabase.storage.from(bucket).upload(`${folder}/${fileName || file.name}`, file)
    if (error) throw error
    return data
  }

  const deleteFile = async (folder, fileName) => await supabase.storage.from(bucket).remove(`${folder}/${fileName}`)

  const downloadFile = async (folder, fileName) => {
    console.log("downloadFile", folder, fileName)
    const { data } = await supabase.storage.from(bucket).download(`${folder}/${fileName}`)
    saveAs(data, fileName)
  }

  return { createFile, listFiles, downloadFile, deleteFile, getLastestFileFrom, getImageURLWithToken }
}
