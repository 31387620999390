import { useEffect, useState } from "react"
import { useSupabase } from "./useSupabase"

const aggregateCustomers = (data) => {
  const dates = {}
  const totals = {}

  const aggregated = data.reduce((acc, { strategy, customer, target, class_name, date, amount }) => {
    const groupBy = `${strategy}-${class_name}`
    if (!acc[groupBy]) acc[groupBy] = { strategy, class_name }
    acc[groupBy][customer] = target

    dates[customer] = date.substring(0, 10)

    if (!totals[customer]) totals[customer] = 0
    totals[customer] += amount

    return acc
  }, {})

  return { excel: Object.values(aggregated), dates, totals }
}

export const useIPS = () => {
  const supbase = useSupabase()
  const [ips, setIPS] = useState()
  const [dates, setDates] = useState()
  const [totals, setTotals] = useState()
  const [excelAllClients, setExcelAllClients] = useState()

  useEffect(() => {
    const fetch = async () => {
      const { data } = await supbase.from("view_ips_and_current").select("*")
      const { excel, dates, totals } = aggregateCustomers(data)
      setIPS(data)
      setDates(dates)
      setExcelAllClients(excel)
      setTotals(totals)
    }

    fetch()
  }, [])

  return { ips, dates, excelAllClients, totals }
}
