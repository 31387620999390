import { Layout, Menu } from "antd"
import React from "react"
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom"
import { PipelineProvider } from "../../context/PipelineContext"
import { spaces } from "../../utils/styles"
import { Logo } from "../atoms/images/Logo/Logo"
import { AssetsPosition } from "../templates/AssetsPosition/AssetsPosition"
import { Compliance } from "../templates/Compliance/Compliance"
import { IPS } from "../templates/IPS/IPS"
import { Pipeline } from "../templates/Pipeline/Pipeline"
import { Risk } from "../templates/Risk/Risk"
import { Similarity } from "../templates/Similarity/Similarity"

function Home() {
  const HEADER_HEIGHT = 64
  const FOOTER_HEIGHT = 0

  const menuItems = [
    {
      key: 1,
      path: "/posicoes",
      label: "Posições",
      children: <AssetsPosition />,
      default: true,
    },
    {
      key: 2,
      path: "/enquadramento",
      label: "Enquadramento",
      children: <Compliance />,
    },
    {
      key: 3,
      path: "/risco",
      label: "Risco",
      children: <Risk />,
    },
    {
      key: 4,
      path: "/similaridade",
      label: "Similaridade",
      children: <Similarity />,
    },
    {
      key: 5,
      path: "/ips",
      label: "IPS",
      children: <IPS />,
    },
    {
      key: 6,
      path: "/pipeline",
      label: "Pipeline",
      children: (
        <PipelineProvider>
          <Pipeline />
        </PipelineProvider>
      ),
    },
  ]

  const currentPath = useLocation().pathname

  const defaultItem = menuItems.find((item) => item.default)

  return (
    <Layout style={{ height: "100vh" }}>
      <Layout.Header
        style={{
          display: "flex",
          alignItems: "center",
          paddingRight: spaces.space5,
          paddingLeft: spaces.space5,
          position: "sticky",
          top: 0,
          zIndex: 1,
          height: HEADER_HEIGHT,
        }}
      >
        <Logo width={95} height={25} marginRight={spaces.space5} />
        <Menu
          mode="horizontal"
          theme="dark"
          selectedKeys={[menuItems.find((item) => currentPath.startsWith(item.path))?.key.toString()]}
          items={menuItems.map((item) => ({
            key: item.key.toString(),
            label: <Link to={item.path}>{item.label}</Link>,
          }))}
          style={{ flex: 1, minWidth: 0 }}
        />
      </Layout.Header>
      <Layout.Content
        style={{
          overflow: "auto",
          backgroundColor: "white",
          height: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
        }}
      >
        <Routes>
          <Route path="*" element={<Navigate to={defaultItem.path} />} />
          {menuItems.map((item) => (
            <Route path={item.path} element={item.children} />
          ))}
        </Routes>
      </Layout.Content>
    </Layout>
  )
}

export default Home
