import { Flex, Select, Tag } from "antd"
import { useContext } from "react"
import { GlobalContext } from "../../../../context/GlobalContext"
import { spaces } from "../../../../utils/styles"
import { classColors } from "../../../../utils/utils"

export const AssetClassSelect = ({ setAssetClass, defaultValue, value, disabled, useOnlyParents = true, width = 250 }) => {
  const contextClasses = useContext(GlobalContext).classes
  const classes = useOnlyParents ? contextClasses.filter((assetClass) => assetClass.id_parent === null) : contextClasses

  const update = (value) => {
    if (value === defaultValue) setAssetClass(null)
    else setAssetClass(value)
  }

  return (
    <Flex gap={spaces.space1} style={{ alignItems: "center" }}>
      <b>Classe:</b>
      <Select
        showSearch
        style={{ width }}
        onChange={update}
        value={value}
        disabled={disabled}
        optionFilterProp="children"
        filterOption={(input, option) => option.children?.props?.children?.toLowerCase().includes(input.toLowerCase())}
        notFoundContent="Classe não encontrada"
      >
        {defaultValue && (
          <Select.Option value={defaultValue} key={defaultValue}>
            {defaultValue}
          </Select.Option>
        )}
        {classes.map((assetClass) => (
          <Select.Option key={assetClass.id} value={assetClass.id}>
            <Tag color={classColors[assetClass.name]}>{assetClass.name}</Tag>
          </Select.Option>
        ))}
      </Select>
    </Flex>
  )
}
