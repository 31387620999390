import { Collapse, Tabs } from "antd"
import { useEffect, useState } from "react"
import "./Compliance.css"
import { useCompliance } from "../../../hooks/useCompliance"
import { Table } from "../../molecules/tables/Table/Table"

export const Compliance = () => {
  const [selectedKey, setSelectedKey] = useState(1)
  const [data, setData] = useState([])
  const { getTeraVehicle, getClientFunds } = useCompliance()

  useEffect(() => {
    if (selectedKey === 1) getClientFunds().then(setData)
    else if (selectedKey === 2) getTeraVehicle().then(setData)
  }, [selectedKey])

  const fullSize = { height: "100%", width: "100%" }

  const columns = [
    {
      title: "Regra",
      dataIndex: "rule",
      key: "rule",
      width: 300,
      render: (text) => (
        <div style={{ whiteSpace: "pre-wrap" }} data-hj-suppress>
          {text}
        </div>
      ),
    },
    {
      title: "Estado atual",
      dataIndex: "current",
      key: "current",
      width: 75,
      className: "smartlook-hide",
    },
    {
      title: "Grupo",
      dataIndex: "group",
      key: "group",
      width: 200,
      className: "smartlook-hide",
    },
  ]

  const children = (
    <Collapse
      accordion
      style={fullSize}
      items={Object.entries(data).map(([key, value]) => ({
        key,
        label: key,
        children: <Table columns={columns} dataSource={value.map((item) => ({ ...item, key: item.id }))} pagination={false} scroll={{ y: 0 }} />,
      }))}
    />
  )

  const items = [
    {
      key: 1,
      label: "Fundos de Clientes",
      children,
    },
    {
      key: 2,
      label: "Veículos TERA",
      children,
    },
  ]

  return <Tabs style={fullSize} items={items} onChange={setSelectedKey} />
}
