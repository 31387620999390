import { useContext } from "react"
import { Button, Flex } from "antd"
import { Logo } from "../atoms/images/Logo/Logo"
import { WindowsFilled } from "@ant-design/icons"
import { GlobalContext } from "../../context/GlobalContext"
import { spaces } from "../../utils/styles"

export default function Login() {
  const { supabase } = useContext(GlobalContext)

  const handleAzureLogin = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "azure",
      options: {
        scopes: "email",
        redirectTo: window.location.href,
      },
    })
    if (error) {
      console.error("Error logging in with Azure:", error.message)
    }
  }

  return (
    <Flex
      gap={spaces.space5}
      style={{
        width: "max-content",
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgb(52, 52, 52)",
        justifyContent: "center",
        alignItems: "center",
      }}
      vertical
    >
      <Logo width={300} />
      <Button onClick={handleAzureLogin} type="primary" icon={<WindowsFilled />} style={{ width: 300, height: 50 }}>
        Login com Microsoft
      </Button>
    </Flex>
  )
}
