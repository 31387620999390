import { Tabs } from "antd"
import { useEffect, useState } from "react"
import { filesQuantFormats } from "../../../../utils/utils"
import { useStorage } from "../../../../hooks/useStorage"

export const StaticViewer = ({ files }) => {
  const [children, setChildren] = useState(null)
  const [isPDF, setIsPDF] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const { getLastestFileFrom } = useStorage({})

  const createPDFViewer = async (folder) => {
    const file = await getLastestFileFrom(folder, true)
    const url = URL.createObjectURL(file)
    setChildren(url)
    setIsPDF(true)
  }

  const createHTMLViewer = async (folder) => {
    const html = await getLastestFileFrom(folder)
    setChildren(html)
    setIsPDF(false)
  }

  const clean = () => {
    URL.revokeObjectURL(children)
    setChildren(null)
    setIsPDF(false)
  }

  useEffect(() => {
    return () => clean()
  }, [])

  useEffect(() => {
    const { folder, format } = files[selectedTab]
    if (format === filesQuantFormats.PDF) createPDFViewer(folder)
    if (format === filesQuantFormats.HTML) createHTMLViewer(folder)
  }, [selectedTab])

  const fullSize = { height: "100%", width: "100%" }
  const commomProps = { style: fullSize, frameBorder: 0 }

  return (
    <>
      <Tabs items={files.map((file, index) => ({ key: index, label: file.title }))} onChange={setSelectedTab} />
      <div style={{ height: "100%", width: "100%" }}>
        {isPDF && <iframe src={children} {...commomProps} />}
        {!isPDF && <iframe srcDoc={children} {...commomProps} />}
      </div>
    </>
  )
}
