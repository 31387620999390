import "./AssetsPositionTable.css"
import React, { useContext, useEffect, useState } from "react"
import { Skeleton, Space, Table, Tooltip } from "antd"
import { PercentLabel } from "../../../atoms/formatters/PercentLabel/PercentLabel"
import { AssetsContext } from "../../../../context/AssetsContext"
import { ASSET_VISUALIZATION_TYPE, CURRENCY } from "../../../../utils/enums"
import { MoneyLabel } from "../../../atoms/formatters/MoneyLabel/MoneyLabel"
import { CaretDownOutlined, CaretRightOutlined, ExclamationCircleFilled } from "@ant-design/icons"
import { SimpleRoundedValueLabel } from "../../../atoms/formatters/SimpleRoundedValueLabel/SimpleRoundedValueLabel"
import { AssetInfoModal } from "../../modals/AssetInfoModal/AssetInfoModal"
import { filterChildrenRecursively, getAllKeys } from "../../../../utils/utils"
import { borders, colors, spaces } from "../../../../utils/styles"

export const AssetsPositionTable = ({ columnWidth = 100, expandAll = false, showOnlyAssets = false }) => {
  const skeleton = <Skeleton.Input active />
  const { data, customers, visualizationType, customerSelectedId, isLoading } = useContext(AssetsContext)
  const [assetToShowModal, setAssetToShowModal] = useState(null)
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [allKeys, setAllKeys] = useState([])

  useEffect(() => setAllKeys(getAllKeys(data)), [data])
  useEffect(() => setExpandedRowKeys(expandAll ? allKeys : []), [expandAll])

  const onExpand = (record) => {
    const keys = expandedRowKeys.includes(record.key) ? expandedRowKeys.filter((key) => key !== record.key) : [...expandedRowKeys, record.key]
    setExpandedRowKeys(keys)
  }

  const assetClassColumn = {
    title: "Ativo",
    dataIndex: "assetClass",
    key: "assetClass",
    align: "left",
    fixed: customerSelectedId ? false : "left",
    render: (text, record) => {
      if (isLoading) return skeleton

      const isParent = record.isParent

      return (
        <span
          style={{
            textAlign: "center",
            whiteSpace: "nowrap",
            fontWeight: isParent || showOnlyAssets ? "bold" : "normal",
            cursor: "pointer",
          }}
          className={isParent ? "smartlook-hide" : ""}
          onClick={() => (isParent ? onExpand(record) : setAssetToShowModal(record))}
          data-hj-suppress
        >
          {text}
          {record.isIlliquid && (
            <Tooltip title="Ativo Ilíquido">
              <ExclamationCircleFilled style={{ marginLeft: spaces.space1, color: colors.primary }} />
            </Tooltip>
          )}
        </span>
      )
    },
  }

  const customersToShow = customerSelectedId ? customers.filter(({ id }) => id === customerSelectedId) : customers

  const isPercent = visualizationType === ASSET_VISUALIZATION_TYPE.percent_on_total.value
  const isAmountBRL = visualizationType === ASSET_VISUALIZATION_TYPE.amount_brl.value
  const isAmountUSD = visualizationType === ASSET_VISUALIZATION_TYPE.amount_usd.value
  const isQuantity = visualizationType === ASSET_VISUALIZATION_TYPE.quantity.value

  const columns = customersToShow.map(({ name: customer }) => ({
    title: customer,
    dataIndex: customer,
    key: customer,
    className: "nowrap",
    width: customerSelectedId ? "calc(100vw - 500px)" : columnWidth,
    render: (value) => {
      if (isLoading) {
        return skeleton
      }
      if (isPercent) {
        return <PercentLabel value={value} data-hj-suppress />
      }
      if (isAmountBRL) {
        return <MoneyLabel value={value} currency={CURRENCY.brl} data-hj-suppress />
      }
      if (isAmountUSD) {
        return <MoneyLabel value={value} currency={CURRENCY.usd} data-hj-suppress />
      }
      if (isQuantity) {
        return <SimpleRoundedValueLabel value={value} data-hj-suppress />
      }

      return <p data-hj-suppress>value</p>
    },
    sorter: customerSelectedId ? (a, b) => a[customer] - b[customer] : false,
  }))

  const rowClassName = (_, index) => (index % 2 === 0 ? "table-row-dark" : "")

  const expandIcon = ({ expanded, record }) => {
    if (isLoading) return null
    if (record.children === undefined) return null
    const props = {
      style: { marginRight: spaces.space2, cursor: "pointer" },
      onClick: () => onExpand(record),
    }

    return expanded ? <CaretDownOutlined {...props} /> : <CaretRightOutlined {...props} />
  }

  const cleanClasses = () => filterChildrenRecursively(data).sort((a, b) => a.assetClass.localeCompare(b.assetClass))

  const Footer = () => {
    return (
      <Table.Summary fixed>
        <Table.Summary.Row className="table-summary-row">
          <Table.Summary.Cell index={0}>
            <b>Total</b>
          </Table.Summary.Cell>
          {customersToShow.map(({ name: customer }) => (
            <Table.Summary.Cell index={customer} key={customer} >
              {isPercent && <b>100%</b>}
              {isAmountBRL && (
                <b>
                  <MoneyLabel value={data.reduce((acc, curr) => acc + curr[customer], 0)} currency={CURRENCY.brl} />
                </b>
              )}
              {isAmountUSD && (
                <b>
                  <MoneyLabel value={data.reduce((acc, curr) => acc + curr[customer], 0)} currency={CURRENCY.usd} />
                </b>
              )}
            </Table.Summary.Cell>
          ))}
        </Table.Summary.Row>
      </Table.Summary>
    )
  }

  return (
    <>
      {assetToShowModal && <AssetInfoModal asset={assetToShowModal} onClose={() => setAssetToShowModal(null)} />}
      <Table
        columns={[assetClassColumn, ...columns]}
        dataSource={showOnlyAssets ? cleanClasses() : data}
        rowClassName={rowClassName}
        pagination={false}
        expandable={{ rowExpandable: true, expandIcon, expandedRowKeys, onExpand }}
        scroll={{ y: "calc(100vh - 260px)", x: "max-content" }}
        style={{ border: borders.default, borderRadius: 6, width: "calc(100vw - 32px)", maxHeight: "calc(100vh - 185px)" }}
        locale={{ sortTitle: "Ordenar" }}
        size="small"
        summary={Footer}
      />
    </>
  )
}
