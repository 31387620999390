import { createContext } from "react"
import { DEFAULT_ASSET_LOCALE, DEFAULT_ASSET_VISUALIZATION_TYPE } from "../utils/enums"

export const AssetsContext = createContext({
  data: [],
  customers: [],
  lastUpdate: null,
  visualizationType: DEFAULT_ASSET_VISUALIZATION_TYPE,
  setVisualizationType: () => {},
  customerSelectedId: null,
  setCustomerSelectedId: () => {},
  session: null,
  setSession: () => {},
  locale: DEFAULT_ASSET_LOCALE,
  setLocale: () => {},
  hideEmptyClasses: true,
  setHideEmptyClasses: () => {},
  assetGraph: [],
  setAssetGraph: () => {},
  isLoading: false,
  setIsLoading: () => {},
  setForceReload: () => {},
})
