export const spaces = {
  space0: 4,
  space1: 8,
  space2: 16,
  space3: 24,
  space4: 32,
  space5: 40,
  space6: 48,
  space7: 56,
  space8: 64,
  space9: 72,
}

export const colors = {
  primary: "#f84600",
  gray: "#ececec",
  green: "#217346",
}

export const borders = {
  default: `1px solid #cbcbcc`,
}
