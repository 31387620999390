import { Flex, Select } from "antd"
import { spaces } from "../../../../utils/styles"
import { pipelineStatus } from "../../../../utils/utils"

export const PipelineAssetStatusSelect = ({ status, setStatus, disabled, width = 200 }) => {
  return (
    <Flex gap={spaces.space1} style={{ alignItems: "center" }}>
      <b>Status:</b>
      <Select value={status} onChange={setStatus} style={{ width }} disabled={disabled}>
        {Object.values(pipelineStatus).map((item) => (
          <Select.Option key={item.text} value={item.text}>
            <Flex gap={spaces.space2}>
              {item.icon}
              {item.text}
            </Flex>
          </Select.Option>
        ))}
      </Select>
    </Flex>
  )
}
