const ASSET_VISUALIZATION_TYPE = {
  percent_on_total: {
    value: "percent_on_total",
    label: "% da carteira",
  },
  amount_brl: {
    value: "amount_brl",
    label: "R$ (financeiro)",
  },
  amount_usd: {
    value: "amount_usd",
    label: "US$ (financeiro)",
  },
  quantity: {
    value: "quantity",
    label: "# (quantidade)",
  },
}

const DEFAULT_ASSET_VISUALIZATION_TYPE = ASSET_VISUALIZATION_TYPE.percent_on_total.value

const CURRENCY = {
  brl: "BRL",
  usd: "USD",
}

const ASSET_LOCALES = {
  all: {
    value: "all",
    label: "Consolidado",
  },
  onshore: {
    value: "onshore",
    label: "Onshore",
  },
  offshore: {
    value: "offshore",
    label: "Offshore",
  },
}

const DEFAULT_ASSET_LOCALE = ASSET_LOCALES.all.value

export { ASSET_VISUALIZATION_TYPE, DEFAULT_ASSET_VISUALIZATION_TYPE, CURRENCY, ASSET_LOCALES, DEFAULT_ASSET_LOCALE }
